import React, { useCallback, useState, useRef } from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

const HoldStillTitle = translation => (
  <span className="regular-title">{translation}</span>
);
const ConfirmTitle = translation => (
  <span className="regular-title">{translation}</span>
);
const EditSerialTitle = translation => (
  <span className="regular-title">{translation}</span>
);

const ScanResult = ({
  buttonsDisabled,
  onRescanClick,
  onAcceptClick,
  ocrText,
  updateOCRText,
}) => {
  const intl = useIntl();

  const penIconRef = useRef(null);

  const [isInputBeingEdited, setIsInputBeingEdited] = useState(false);

  const handleInputValueChange = useCallback(event => {
    updateOCRText(event.currentTarget.value);
  });

  const handlePenIconClick = useCallback(event => {
    if (buttonsDisabled) {
      return;
    }

    event.currentTarget.parentNode.childNodes[0].focus();
  });

  const handleInputFocus = () => {
    setIsInputBeingEdited(true);
  };
  const handleInputBlur = () => {
    setIsInputBeingEdited(false);
  };

  let ScanResultCardTitle = buttonsDisabled
    ? HoldStillTitle(
        intl.formatMessage({ id: 'scan.result.titles.hold_still' })
      )
    : ConfirmTitle(intl.formatMessage({ id: 'scan.result.titles.confirm' }));
  let resultInputPlaceholder = intl.formatMessage({
    id: 'scan.result.start_scan_placeholder',
  });

  if (isInputBeingEdited === true) {
    ScanResultCardTitle = EditSerialTitle(
      intl.formatMessage({ id: 'scan.result.titles.edit_serial' })
    );
    resultInputPlaceholder = '';
  }

  return (
    <div className="scan-result">
      <div className="card border-dark">
        <div className="card-title">{ScanResultCardTitle}</div>
        <div className="card-body">
          <div className="result-input">
            <input
              type="text"
              placeholder={resultInputPlaceholder}
              value={ocrText}
              onChange={handleInputValueChange}
              // disabled={buttonsDisabled}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
            <FontAwesomeIcon
              icon={faPen}
              ref={penIconRef}
              onClick={handlePenIconClick}
            />
          </div>
          <div className="buttons">
            <button
              type="button"
              className="btn btn-link"
              // disabled={buttonsDisabled}
              onClick={onRescanClick}
            >
              {intl.formatMessage({ id: 'scan.result.scan_button' })}
            </button>
            <button
              type="button"
              className="btn btn-link"
              disabled={buttonsDisabled}
              onClick={onAcceptClick}
            >
              {intl.formatMessage({ id: 'scan.result.accept_button' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanResult;
