import React from 'react';

import Instruction from './instruction';

const Instructions = ({ instructions, header, description }) => (
  <div className="instructions margin-top">
    <div className="h7">{header}</div>
    <p>{description}</p>
    {instructions &&
      instructions.map((instruction, idx) => (
        <Instruction {...instruction} step={idx + 1} key={idx} />
      ))}
  </div>
);

export default Instructions;
