import React from 'react';

const Jumbotron = ({ firstLine, secondLine }) => (
  <div className="jumbotron">
    <h1>{firstLine}</h1>
    {secondLine && <h1>{secondLine}</h1>}
  </div>
);

export default Jumbotron;
