import { isCameraInputAvailable } from './media';

// effects
export const checkCameraInputEffect = setCameraInputAvailable => {
  async function checkCameraInput() {
    const cameraInputAvailable = await isCameraInputAvailable();

    setCameraInputAvailable(cameraInputAvailable);
  }

  checkCameraInput();
};
