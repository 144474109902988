import React, { useState, useCallback, useEffect } from 'react';

import StartScanning from 'components/modals/start-scanning';
import ModalError from 'components/modals/modal-error';

import ScanningSerial from './scanning-serial';

export const ScanLayout = ({
  initializeScanFunctionality,
  scanInstructionImage,
  onCheckButtonClick,
  onTerminateScanFunctionality,
}) => {
  const [showScanningComponent, setShowScanningComponent] = useState(false);
  const [showStartScanningModal, setShowStartScanningModal] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);

  const handleCloseModalError = useCallback(() => {
    setShowErrorModal(false);
    setErrorMessage(null);
    setErrorTitle(null);

    setShowStartScanningModal(false);
    onTerminateScanFunctionality();
  });

  const handleCloseInstructionModalButtonClick = useCallback(() => {
    setShowStartScanningModal(false);
    onTerminateScanFunctionality();
  });

  const handleStartScanButtonClick = useCallback(() => {
    setShowScanningComponent(true);
  });

  const handlePermissionsAllowed = useCallback(() => {
    setShowStartScanningModal(false);
  });
  const handleCloseScanningSerialClick = useCallback(
    (showErrorModal, errorTitle, errorMessage) => {
      setShowScanningComponent(false);

      if (showErrorModal === true) {
        setShowErrorModal(true);
        setErrorMessage(errorMessage);
        setErrorTitle(errorTitle);
      }

      onTerminateScanFunctionality();
    }
  );
  const handleAcceptSerialNumberClick = useCallback(ocrText => {
    onCheckButtonClick(ocrText);
  });
  const handleScanningSerialError = useCallback((errorTitle, errorMessage) => {
    setShowErrorModal(true);
    setErrorMessage(errorMessage);
    setErrorTitle(errorTitle);
  });

  useEffect(() => {
    if (initializeScanFunctionality === true) {
      setShowStartScanningModal(true);
    }
  }, [initializeScanFunctionality]);

  return (
    <React.Fragment>
      {showErrorModal && (
        <ModalError
          message={errorMessage}
          title={errorTitle}
          onClose={handleCloseModalError}
        />
      )}
      {showStartScanningModal && (
        <StartScanning
          image={scanInstructionImage}
          onClose={handleCloseInstructionModalButtonClick}
          onStartScanButtonClick={handleStartScanButtonClick}
        />
      )}
      {showScanningComponent && (
        <ScanningSerial
          onPermissionsAllowed={handlePermissionsAllowed}
          onCloseClick={handleCloseScanningSerialClick}
          onAcceptSerialNumberClick={handleAcceptSerialNumberClick}
          onError={handleScanningSerialError}
        />
      )}
    </React.Fragment>
  );
};

export default ScanLayout;
