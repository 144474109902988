import React from 'react';

import footerLogo from '../../../content/images/powered_by_edge_lq_wt_logo.svg';

import './style.scss';

const Footer = () => (
  <div className="footer">
    <div className="footer-logo logo-box">
      <img src={footerLogo} />
    </div>
  </div>
);

export default Footer;
