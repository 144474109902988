import React from 'react';

import { injectIntl } from 'gatsby-plugin-intl';

class StartScanningModal extends React.Component {
  render() {
    const { image, onClose, onStartScanButtonClick, intl } = this.props;

    const instructions = [
      intl.formatMessage({ id: 'scan.start_scanning_modal.instructions.0' }),
      intl.formatMessage({ id: 'scan.start_scanning_modal.instructions.1' }),
      intl.formatMessage({ id: 'scan.start_scanning_modal.instructions.2' }),
      intl.formatMessage({ id: 'scan.start_scanning_modal.instructions.3' }),
      intl.formatMessage({ id: 'scan.start_scanning_modal.instructions.4' }),
    ];

    return (
      <React.Fragment>
        <div className="modal fade show scan-info">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {intl.formatMessage({
                    id: 'scan.start_scanning_modal.title',
                  })}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ol className="scan-instructions-list">
                  {instructions.length &&
                    instructions.map((item, key) => {
                      return <li key={key}>{item}</li>;
                    })}
                </ol>

                <div className="scan-image text-center">
                  <img src={image} alt="Scan image" />
                </div>

                <div className="text-center">
                  <button
                    className="btn start-scan-button"
                    onClick={onStartScanButtonClick}
                  >
                    {intl.formatMessage({
                      id: 'scan.start_scanning_modal.start_button',
                    })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </React.Fragment>
    );
  }
}

export const StartScanning = injectIntl(StartScanningModal);

export default injectIntl(StartScanningModal);
