import React from 'react';

import { useIntl } from 'gatsby-plugin-intl';

const QuitCameraMode = ({ onCloseClick }) => {
  const intl = useIntl();

  return (
    <div className="quit-camera-mode" onClick={onCloseClick}>
      <span>{intl.formatMessage({ id: 'scan.camera_mode.quit' })}</span>
      <span className="btn-close" onClick={onCloseClick}>
        &times;
      </span>
    </div>
  );
};

export default QuitCameraMode;
