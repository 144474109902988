import React, { createRef } from 'react';

import { injectIntl } from 'gatsby-plugin-intl';

import Tesseract from 'tesseract.js';

import { videoConstraints } from '../../utils/media';

import CameraHandler from './camera';
import ScanResult from './scan-result';
import QuitCameraMode from './quit-camera-mode';
import ModalError from 'components/modals/modal-error';

export const OCR_STATUS_TO_CLASS = {
  loading: 'loading',
  ready: 'ready',
  recognizing: 'recognizing',
  recognized: 'recognized',
  timeout: 'timeout',
};

const TIMEOUT_IN_MS = 90000;

class ScanningSerialComp extends React.Component {
  constructor(props) {
    super(props);

    this.tesseractWorkerInstance = null;
    this.cameraRef = createRef();
    this.timeoutId = null;

    this.state = {
      showCamera: false,
      stream: null,
      ocrText: '',
      ocrStatus: OCR_STATUS_TO_CLASS.loading,
      showErrorModal: false,
    };
  }

  initializeTesseract = async () => {
    console.log('initialize tesseract');

    await this.tesseractWorkerInstance.load();
    await this.tesseractWorkerInstance.loadLanguage('eng');
    await this.tesseractWorkerInstance.initialize('eng');
    await this.tesseractWorkerInstance.setParameters({
      tessedit_char_whitelist: '0123456789abcdefghijklmnopqrstuvwxyz',
    });

    this.setState(
      {
        ocrStatus: OCR_STATUS_TO_CLASS.ready,
      },
      () => {
        this.timeoutId = window.setTimeout(this.handleTimeout, TIMEOUT_IN_MS);
      }
    );
  };

  handleRunOCRSuccess = (ocrResult, successCb, errorCb) => {
    console.log('text', ocrResult.data.text);

    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;

    this.setState({
      ocrStatus: OCR_STATUS_TO_CLASS.recognized,
      ocrText: ocrResult.data.text,
    });
  };

  validateOCRResult = ocrResult => {
    let valid = true;

    if (ocrResult.length === 0) {
      valid = false;
    } else if (ocrResult.length !== 16) {
      valid = false;
    }

    return valid;
  };

  updateOCRText = text => {
    this.setState({
      ocrText: text,
    });
  };

  runOCR = async (imageData, successCb, errorCb) => {
    if (this.state.showErrorModal === false) {
      this.setState(
        {
          ocrStatus: OCR_STATUS_TO_CLASS.recognizing,
        },
        async () => {
          // console.log('recognizing');
          try {
            const ocrData = await this.tesseractWorkerInstance.recognize(
              imageData
            );

            if (this.validateOCRResult(ocrData.data.text) === false) {
              this.runOCR(imageData, successCb, errorCb);
            } else {
              this.handleRunOCRSuccess(ocrData, successCb, errorCb);
            }
          } catch (error) {
            console.log('runOCR error', error);

            errorCb(error);
          }
        }
      );
    } else {
      this.setState({
        ocrStatus: OCR_STATUS_TO_CLASS.recognized,
      });
    }
  };

  async componentDidMount() {
    const { intl } = this.props;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });

      this.tesseractWorkerInstance = Tesseract.createWorker({
        logger: message => console.log(message),
      });

      this.setState(
        {
          stream,
          showCamera: true,
        },
        this.props.onPermissionsAllowed
      );
    } catch (error) {
      console.log('Getting stream error', error);

      // show error modal
      this.props.onCloseClick(
        true,
        intl.formatMessage({ id: 'scan.error_modal.camera_error.title' }),
        intl.formatMessage({ id: 'scan.error_modal.camera_error.description' })
      );
    }
  }

  componentWillUnmount() {
    if (this.tesseractWorkerInstance !== null) {
      this.tesseractWorkerInstance.terminate();
    }

    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  handleRescanClick = () => {
    this.setState(
      {
        // clear input
        ocrText: '',
      },
      () => {
        this.cameraRef.current.passCanvasToOCR();

        this.timeoutId = window.setTimeout(this.handleTimeout, TIMEOUT_IN_MS);
      }
    );
  };

  handleAcceptClick = () => {
    this.props.onAcceptSerialNumberClick(this.state.ocrText);
  };

  handleTimeout = () => {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;

    this.setState({
      showErrorModal: true,
      ocrStatus: OCR_STATUS_TO_CLASS.timeout,
    });
  };

  handleCloseErrorModal = () => {
    this.setState({
      showErrorModal: false,
    });
  };

  render() {
    const {
      showCamera,
      stream,
      ocrText,
      ocrStatus,
      showErrorModal,
    } = this.state;
    const { onCloseClick, intl } = this.props;

    const buttonsDisabled =
      ocrStatus !== OCR_STATUS_TO_CLASS.recognized &&
      ocrStatus !== OCR_STATUS_TO_CLASS.timeout;

    return (
      <div
        className={`scanning-serial ${
          stream !== null ? 'dark-background' : ''
        }`}
      >
        {showCamera && (
          <React.Fragment>
            {showErrorModal && (
              <ModalError
                message={intl.formatMessage({
                  id: 'scan.error_modal.scan_problem.message',
                })}
                onClose={this.handleCloseErrorModal}
                title={intl.formatMessage({
                  id: 'scan.error_modal.scan_problem.title',
                })}
                closeText={intl.formatMessage({
                  id: 'scan.error_modal.scan_problem.close_text',
                })}
              />
            )}
            <div className="relative">
              <QuitCameraMode onCloseClick={onCloseClick} />
              <CameraHandler
                stream={stream}
                initialize={this.initializeTesseract}
                runOCR={this.runOCR}
                ocrStatus={ocrStatus}
                ref={this.cameraRef}
              />
              <ScanResult
                onRescanClick={this.handleRescanClick}
                onAcceptClick={this.handleAcceptClick}
                buttonsDisabled={buttonsDisabled}
                ocrText={ocrText}
                updateOCRText={this.updateOCRText}
                ocrStatus={ocrStatus}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export const ScanningSerial = injectIntl(ScanningSerialComp);

export default injectIntl(ScanningSerialComp);
