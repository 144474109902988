import React from 'react';
import { Link } from 'gatsby-plugin-intl';

import watchdogLogo from '../../../content/images/watchdog_logo.svg';

const Header = () => (
  <header>
    <Link to="/">
      <img className="logo" src={watchdogLogo} />
    </Link>
  </header>
);

export default Header;
