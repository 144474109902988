import React, { useCallback } from 'react';

export const ModalError = ({ onClose, message, title, closeText }) => {
  const handleClickCloseButton = useCallback(() => {
    onClose();
  });

  return (
    <React.Fragment>
      <div className="modal fade show scan-error">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClickCloseButton}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{message}</div>
            <div className="modal-footer">
              <button className="btn btn-link" onClick={handleClickCloseButton}>
                {closeText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show scan-error-backdrop" />
    </React.Fragment>
  );
};

export default ModalError;
