export const areMediaDevicesAvailable = () => {
  let result = false;

  if (navigator && navigator.mediaDevices !== undefined) {
    result = true;
  }

  return result;
};

export const isCameraInputAvailable = async () => {
  let result = false;

  try {
    const mediaDevicesAvailable = areMediaDevicesAvailable();

    if (
      mediaDevicesAvailable &&
      navigator.mediaDevices.getUserMedia &&
      navigator.mediaDevices.enumerateDevices
    ) {
      const devices = await navigator.mediaDevices.enumerateDevices();

      result = devices.some(device => device.kind === 'videoinput');
    }
  } catch (error) {
    console.error('isCameraInputAvailable', error);

    result = false;
  }

  return result;
};

export const videoConstraints = {
  facingMode: 'environment',
};
