import React, { useCallback, useRef } from 'react';

import { useIntl } from 'gatsby-plugin-intl';

import serialScanLogo from '../../../content/images/scan.svg';

const SerialNumber = ({
  useCamera,
  onCheckButtonClick,
  currentValue,
  placeholder,
  className,
  onScanButtonClick,
}) => {
  const intl = useIntl();

  const serialNumberCurrentValue = currentValue ? currentValue : '';
  const serialNumberInputRef = useRef(serialNumberCurrentValue);

  const handleCheckButtonClick = useCallback(() => {
    const serialNumberValue = serialNumberInputRef.current.value;

    if (!serialNumberValue) {
      console.log('no value');

      return;
    }

    onCheckButtonClick(serialNumberInputRef.current.value);
  });

  const handleScanButtonClick = useCallback(() => {
    onScanButtonClick();
  });

  return (
    <div className={`input-group serial-number margin-top ${className}`}>
      <input
        type="text"
        className="form-control"
        ref={serialNumberInputRef}
        defaultValue={serialNumberCurrentValue}
        placeholder={placeholder}
      />
      <div className="input-group-append">
        {useCamera && (
          <span
            className="input-group-text scan-button"
            onClick={handleScanButtonClick}
          >
            <img src={serialScanLogo} alt="Scan serial" />
          </span>
        )}
        <button className="btn check-button" onClick={handleCheckButtonClick}>
          {intl.formatMessage({ id: 'serial_number_input.check_button' })}
        </button>
      </div>
    </div>
  );
};

export default SerialNumber;
