import React, { createRef } from 'react';

import { OCR_STATUS_TO_CLASS } from './scanning-serial';
import { LoadingDots } from './loading-dots';

const CLIPPING_WIDTH = 250;
const CLIPPING_HEIGHT = 44;

class CameraHandler extends React.Component {
  constructor(props) {
    super(props);

    this.videoRef = createRef();
    this.canvasRef = createRef();
    this.canvasWrapperRef = createRef();
    this.clippingWindowRef = createRef();

    this.canvasCtx = null;

    let windowHeight = 0;
    let windowWidth = 0;

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      windowHeight = window.innerHeight;
      windowWidth = window.innerWidth;
    }

    this.state = {
      loading: true, // loading Tesseract
      windowWidth,
      windowHeight,
      ocrState: OCR_STATUS_TO_CLASS.loading,
    };
  }

  async componentDidMount() {
    const { initialize, stream } = this.props;

    this.canvasCtx = this.canvasRef.current.getContext('2d');

    this.videoRef.current.srcObject = stream;
    this.videoRef.current.onloadedmetadata = event => {
      this.videoRef.current.play();

      const width = this.canvasWrapperRef.current.clientWidth;

      const clippingSx = width / 2 - CLIPPING_WIDTH / 2;
      const clippingSy = parseFloat(
        window.getComputedStyle(this.clippingWindowRef.current).top,
        10
      );

      const capturingClippingWindow = () => {
        if (this.canvasCtx !== null) {
          this.canvasCtx.drawImage(
            this.videoRef.current,
            clippingSx,
            clippingSy,
            CLIPPING_WIDTH,
            CLIPPING_HEIGHT,
            0,
            0,
            CLIPPING_WIDTH,
            CLIPPING_HEIGHT
          );

          window.requestAnimationFrame(capturingClippingWindow);
        }
      };

      window.requestAnimationFrame(capturingClippingWindow);
    };

    await initialize();

    this.setState({
      loading: false,
    });

    console.log('initialized');
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ocrStatus === OCR_STATUS_TO_CLASS.loading &&
      this.props.ocrStatus === OCR_STATUS_TO_CLASS.ready
    ) {
      this.passCanvasToOCR();
    }
  }

  componentWillUnmount() {
    this.canvasCtx = null;
  }

  passCanvasToOCR = () => {
    this.props.runOCR(
      this.canvasRef.current,
      () => console.log('success'),
      () => console.log('error')
    );
  };

  render() {
    const { ocrStatus } = this.props;

    return (
      <React.Fragment>
        <div className="clipping-mask">
          <div className="clipping-window-relative" ref={this.canvasWrapperRef}>
            <div
              className={`clipping-window ${ocrStatus}`}
              ref={this.clippingWindowRef}
            >
              <canvas
                ref={this.canvasRef}
                width={CLIPPING_WIDTH}
                height={CLIPPING_HEIGHT}
              />
              {ocrStatus === OCR_STATUS_TO_CLASS.recognizing && <LoadingDots />}
            </div>
          </div>
        </div>
        <div className="camera-capturing">
          <video autoPlay={true} ref={this.videoRef} playsInline={true} />
        </div>
      </React.Fragment>
    );
  }
}

export default CameraHandler;
