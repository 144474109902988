import React from 'react';

const Instruction = ({ title, desc, image, step }) => (
  <div className="instruction">
    <div className="h7">
      {step}. {title}
    </div>
    <p>{desc}</p>
    {image && (
      <p className="instruction-image">
        <img src={image} />
      </p>
    )}
  </div>
);

export default Instruction;
