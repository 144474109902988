import React from 'react';
import emergence from 'emergence.js';

import { injectIntl } from 'gatsby-plugin-intl';

import Header from 'components/header';
import Jumbotron from 'components/jumbotron/';
import Footer from 'components/footer';

import 'modern-normalize/modern-normalize.css';
import 'prismjs/themes/prism.css';
import 'scss/gatstrap.scss';
import 'animate.css/animate.css';
import 'font-awesome/css/font-awesome.css';
import 'scss/watchdog.scss';

class Layout extends React.Component {
  componentDidMount() {
    emergence.init();
  }

  render() {
    const { children, intl } = this.props;

    const jumbotronFirstLine = intl.formatMessage({
      id: 'jumbotron.firstLine',
    });
    const jumbotronSecondLine = intl.formatMessage({
      id: 'jumbotron.secondLine',
    });

    return (
      <div className="container-fluid sticky-footer">
        <Header {...this.props} />
        <Jumbotron
          firstLine={jumbotronFirstLine}
          secondLine={jumbotronSecondLine}
        />
        <div className="content-wrap">{children}</div>
        <Footer />
      </div>
    );
  }
}

export default injectIntl(Layout);
